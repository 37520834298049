import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Snackbar,
  Alert,
  IconButton,
  Box,
  CircularProgress,
  TablePagination,
  Switch,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import SearchIcon from "@mui/icons-material/Search";

const ParentsTable = () => {
  const [parents, setParents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [statusToggle, setStatusToggle] = useState(false);
  const [totalItems, setTotalItems] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState({
    grade: "",
    gradeDivision: "",
  });

  useEffect(() => {
    fetchParents();
  }, [searchTerm, page, rowsPerPage]);

  const fetchParents = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("Unauthorized user");
        return;
      }
      const response = await axios.get(
        `${
          process.env.REACT_APP_BACKEND
        }/api/parent?search=${searchTerm}&page=${
          page + 1
        }&limit=${rowsPerPage}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data;
      console.log("parent data: ", data);

      if (data?.data) {
        const parentsWithSerial = data.data.map((parent, index) => ({
          ...parent,
          serial: index + 1,
        }));
        setParents(parentsWithSerial);
        setSerialNumbers(parentsWithSerial.map((p) => p.serial));
        setTotalItems(data.pagination.totalItems);
        console.log(data.pagination.totalItems);
      } else {
        setError("No parents found");
      }
    } catch (err) {
      setError("Failed to fetch parents");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchgradebySchool = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/student/grades/schoolData`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const gradeData = response.data.data;
        setGrades(gradeData);
        console.log("gradeData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    };
    fetchgradebySchool();
  }, []);

  useEffect(() => {
    const fetchstudentBygradedivision = async () => {
      if (selectedGrade.grade === "") {
        // If "Select All" is chosen, fetch all students
        fetchParents();
        return;
      }
      if (!selectedGrade.gradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/parent/parentBygradeId/${selectedGrade.gradeDivision}?search=${searchTerm}&page=${
          page + 1
        }&limit=${rowsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        if (data?.data) {
          const parentsWithSerial = data.data.map((parent, index) => ({
            ...parent,
            serial: index + 1,
          }));
          setParents(parentsWithSerial);
          setSerialNumbers(parentsWithSerial.map((p) => p.serial));
          setTotalItems(data.pagination.totalItems);
        }else {
          setError("No parents found");
        }
        // console.log("students: ", response.data.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    fetchstudentBygradedivision();
  }, [selectedGrade.gradeDivision]);

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const deleteParent = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/parent/${deleteId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      if (response.status === 200) {
        setSnackbarMessage("Parent deleted successfully");
        setOpenSnackbar(true);
        fetchParents();
        handleCloseDeleteDialog();
      }
    } catch (err) {
      setError("Failed to delete parent");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };
  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateParentStatus(id, newStatus); // Update parent status in the backend
  };
  const updateParentStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/parent/${id}/status_parent`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Parent status updated successfully");
      setOpenSnackbar(true);
      fetchParents(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update parent status");
    }
  };

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#e0e0e0" }}
        p={1}>
        <Typography variant="h4">Parents</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingRight: "0", paddingLeft: "0" }}>
        <Autocomplete
        size="small"
          options={grades}
          getOptionLabel={(option) =>
            option.grade_id?.gradename
              ? `${option.grade_id.gradename} ${option.division}`
              : ""
          }
          value={
            grades.find((item) => item._id === selectedGrade.grade) || null
          }
          onChange={(e, newValue) => {
            if (newValue) {
              const selectedGradeItem = grades.find(
                (item) => item._id === newValue._id
              );
              const gradeDivision = `${
                selectedGradeItem.grade_id?.gradename || ""
              } ${selectedGradeItem.division || ""}`;
              setSelectedGrade({
                grade: newValue._id,
                gradeDivision,
              });
            } else {
              setSelectedGrade({
                grade: "",
                gradeDivision: "",
              });
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Grade" />
          )}
          sx={{ width: 350 }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Student Id</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Class</TableCell>
              <TableCell>Contact Number</TableCell>
              <TableCell>Relation to Student</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parents.length > 0 ? (
              parents.map((parent) => (
                <TableRow key={parent._id}>
                  <TableCell>{parent.serial}</TableCell>
                  <TableCell>{parent?.loginData?.username || "old data"}</TableCell>
                  <TableCell>{parent?.loginData?.real_password || "old data"}</TableCell>
                  {/* <TableCell>{parent.parent_id}</TableCell> */}
                  <TableCell>{parent.parents_name}</TableCell>
                  <TableCell>{parent.p_email}</TableCell>
                  <TableCell>           
                             {parent.student_id ? parent.student_id.student_id : "N/A"}
                  </TableCell>

                  <TableCell>
                    {parent.student_id ? parent.student_id.name : "N/A"}
                  </TableCell>
                  <TableCell>
                    {parent.grade_division ? parent.grade_division : "N/A"}
                  </TableCell>
                  <TableCell>{parent?.contact_num}</TableCell>
                  <TableCell>{parent?.relation_to_student}</TableCell>
                  <TableCell>
                    <Switch
                      checked={parent.status}
                      onChange={() =>
                        handleStatusToggle(parent._id, parent.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      // onClick={() => deleteParent(parent._id)}>
                      onClick={() => handleOpenDeleteDialog(parent._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  No parents available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems > 100 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(_, newRowsPerPage) =>
              setRowsPerPage(newRowsPerPage)
            }
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this parent?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteParent}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ParentsTable;
