import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import { margin, maxWidth } from "@mui/system";

const AddStudentPage = () => {
  const [student, setStudent] = useState({
    sname: "",
    grade_id: "",
    grade_division: "",
    mobile: "",
    email: "",
    address: "",
    admission_Date: "",
    gender: "",
  });
  const [divisions, setDivisions] = useState([]);

  const [parent, setParent] = useState({
    parents_name: "",
    p_email: "",
    contact_num: "",
    relation_to_student: "",
  });

  const DivisionData = [
    { id: 1, name: "A" },
    { id: 2, name: "B" },
    { id: 3, name: "C" },
    { id: 4, name: "D" },
    { id: 5, name: "E" },
    { id: 6, name: "F" },
    { id: 7, name: "G" },
    { id: 8, name: "H" },
    { id: 9, name: "I" },
    { id: 10, name: "J" },
  ];

  const [profileImage, setProfileImage] = useState(null); // New state for image
  const [profileImagePreview, setProfileImagePreview] = useState(""); // State for the image preview
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [grades, setGrades] = useState([]);
  const [error, setError] = useState(false); // Track the error state
  const [helperText, setHelperText] = useState(""); // Track the helper text

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("name", student.sname);
    formData.append("grade_id", student.grade_id);
    formData.append("mobile", student.mobile);
    formData.append("email", student.email);
    formData.append("address", student.address);
    formData.append("admission_Date", student.admission_Date);
    formData.append("gender", student.gender);
    formData.append("grade_division", student.grade_division);

    formData.append("parents_name", parent.parents_name);
    formData.append("p_email", parent.p_email);
    formData.append("contact_num", parent.contact_num);
    formData.append("relation_to_student", parent.relation_to_student);
    const namePattern = /^[A-Za-z ]*$/; // This allows only alphabets and spaces
    if (!namePattern.test(student.sname)) {
      setError(true); // Set error state to true
      setHelperText("Name must contain only alphabets and spaces"); // Set helper text for error message
    } else {
      setError(false); // No error
      setHelperText(""); // Clear helper text
      // Proceed with form submission logic here
    }
    // Append profile image
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/student`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`, // Uncomment if using auth
            },
          }
        );

        if (response.status === 201) {
          setSnackbarMessage("Student and Parent added successfully");
          navigate("/students");
        } else if (response.status === 202) {
          setSnackbarMessage("Sibling Detected");
          navigate("/students");
        } else {
          setSnackbarMessage("Failed to add Student");
        }

        setOpenSnackbar(true);
      } else {
        setSnackbarMessage("Please login to add student");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 409) {
        console.log("This student email is already exist ");

        setSnackbarMessage("This student email is already exist ");
      }

      console.error(error.response.message); // Log the actual error for debugging

      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(student);

    const fetchgrade = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade/assignedgrade`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        console.log(response.data.data);
        setGrades(response.data.data);
      } catch (error) {
        console.log("Error fetching departments:", error);
      }
    };

    fetchgrade();
  }, []);

  const handleGradeChange = async (e) => {
    const selectedGradeId = e.target.value;
    setStudent((prev) => ({
      ...prev,
      grade_id: selectedGradeId,
    }));

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/grade/divisions/${selectedGradeId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setDivisions(response.data.data);
    } catch (error) {
      console.error("Error fetching divisions:", error);
    }
  };

  const handleCancel = () => {
    navigate('/students')
  }

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="#e0e0e0"
        p={1}
        f
        mt={2}
      >
        <Typography variant="h4">Add Student</Typography>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <form
          onSubmit={handleSubmit}
          sx={{ maxWidth: "0px !important", margin: "0px !important" }}
        >
          <Grid spacing={0} item xs={7}>
            <Grid item xs={12}>
              {/* <TextField
                label="Name"
                value={student.sname}
                onChange={(e) =>
                  setStudent({ ...student, sname: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              /> */}
              <TextField
                label="Student Name"
                value={student.sname}
                onChange={(e) =>
                  setStudent({ ...student, sname: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
                inputProps={{
                  pattern: "[A-Za-z ]*", // This allows only alphabets and spaces
                  title: "Name must contain only alphabets" // This message will show if the pattern is not matched
                }}
                error={error} // Pass the error state here
                helperText={helperText} // Pass the helper text to show the error message
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
                required
              >
                {/* <InputLabel>Grade</InputLabel> */}
                {/* <Select
                  value={student.grade_id}
                  onChange={(e) =>
                    setStudent({
                      ...student,
                      grade_id: e.target.value,
                    })
                  }
                  label="Grade"
                  aria-required="true">
                  {grades.map((grade) => (
                    <MenuItem key={grade.grade_id} value={grade.grade_id}>
                      {grade.grade_name}
                    </MenuItem>
                  ))}
                </Select> */}

                <TextField
                        select
                  labelId="grade-select-label"
                  value={student.grade_id}
                  onChange={handleGradeChange}
                  label="Grade"
                  aria-required="true"
                >
                  {grades.map((grade) => (
                    <MenuItem key={grade.grade_id} value={grade.grade_id}>
                      {grade.grade_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
                required
              >
           
                {/* <Select
                  value={student.grade_division}
                  onChange={(e) =>
                    setStudent({
                      ...student,
                      grade_division: e.target.value,
                    })
                  }
                  label="Division"
                  aria-required="true">
                  {DivisionData.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select> */}

                <TextField
                        select
                  labelId="division-select-label"
                  value={student.grade_division} // Assuming this will hold the division name
                  onChange={(e) =>
                    setStudent((prev) => ({
                      ...prev,
                      grade_division: e.target.value, // Save the division name
                    }))
                  }
                  label="Division"
                  aria-required="true"
                >
                  {divisions.map((division) => (
                    <MenuItem key={division._id.$oid} value={division.division}>
                      {division.division}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mobile"
                type="Number"
                value={student.mobile}
                onChange={(e) =>
                  setStudent({ ...student, mobile: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
                inputProps={{
                  pattern: "^[0-9]{10}$", // This is for validation when submitting
                  title: "Phone number must be 10 digits",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={student.email}
                onChange={(e) =>
                  setStudent({ ...student, email: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                value={student.address}
                onChange={(e) =>
                  setStudent({ ...student, address: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Admission Date"
                type="date"
                value={student.admission_Date}
                onChange={(e) =>
                  setStudent({ ...student, admission_Date: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <TextField
                        select
                  value={student.gender}
                  onChange={(e) =>
                    setStudent({ ...student, gender: e.target.value })
                  }
                  label="Gender"
                  required
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Profile Image
              </Typography>
              <Button variant="contained" component="label">
                Upload Profile Image
                <input type="file" hidden onChange={handleImageChange} />
              </Button>
              {profileImage && (
                <div>
                  <Typography>{profileImage.name}</Typography>
                  <img
                    src={profileImagePreview}
                    alt="Profile Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      marginTop: "10px",
                    }}
                  />
                </div>
              )}
            </Grid>

            {/* Parent Fields */}
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                backgroundColor="#e0e0e0"
                p={1}
                f
                mt={2}
              >
                <Typography variant="h4">Parents Details</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Parent Name"
                value={parent.parents_name}
                onChange={(e) =>
                  setParent({ ...parent, parents_name: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Parent Email"
                value={parent.p_email}
                onChange={(e) =>
                  setParent({ ...parent, p_email: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Parent Contact"
                value={parent.contact_num}
                onChange={(e) =>
                  setParent({ ...parent, contact_num: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                label="Relation to Student"
                value={parent.relation_to_student}
                onChange={(e) =>
                  setParent({ ...parent, relation_to_student: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid> */}

            <Grid item xs={12} md={12}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <TextField
                        select
                  value={parent.relation_to_student}
                  onChange={(e) =>
                    setParent({
                      ...parent,
                      relation_to_student: e.target.value,
                    })
                  }
                  label="Relation to Student"
                  required
                >
                  <MenuItem value="Father">Father</MenuItem>
                  <MenuItem value="Mother">Mother</MenuItem>
                  <MenuItem value="Guardian">Guardian</MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
              <Button onClick={handleCancel} color="primary" sx={{mx:2}}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={
            snackbarMessage === "Student and Parent added successfully"
              ? "success"
              : "error"
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddStudentPage;
