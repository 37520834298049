import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardMedia,
  CardContent,
  Box,
  Container,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  const getOrdinal = (num) => {
    if (num > 3 && num < 21) return "th";
    switch (num % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${getOrdinal(day)} ${month} ${year}`;
};

// Helper function to format time
const formatTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  const adjustedHours = hours % 12 || 12;

  return `${adjustedHours}:${minutes} ${period}`;
};

const NewsListing = () => {
  const [selectedDate, setSelectedDate] = useState(null); // State to hold selected date
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch news based on selected date
  useEffect(() => {
    const formatLocalDate = (date) => {
      // Ensure the date is in the local timezone
      const localDate = new Date(date);
      localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset()); // Adjust the date to local time
      return localDate.toISOString().split("T")[0]; // Format to 'YYYY-MM-DD'
    };
    
    const fetchNews = async () => {
      try {
        const token = getToken();
        if (token) {
          let url = `${process.env.REACT_APP_BACKEND}/api/companynews/date`;
          if (selectedDate) {
            const formattedDate = formatLocalDate(selectedDate); // Use the helper to get the correct local date
            url = `${url}?date=${formattedDate}`; // Append the date query param
          }
    
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          setNews(response.data.data); // Set the filtered news
          setLoading(false); // Stop loading when data is fetched
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading on error
      }
    };    

    fetchNews();
  }, [selectedDate]); // Re-fetch news when selectedDate changes

  // Handle date change for filtering
  const handleDateChange = (date) => {
    setSelectedDate(date); // Update the selected date
  };

  // Reset date filter
  const handleReset = () => {
    setSelectedDate(null);
  };

  return (
    <Container maxWidth="100%" sx={{ padding: 2 }}>
      {/* Date Filter Section */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 2,
            mb: 4,
            justifyContent: "space-between",
          }}
        >
          <DatePicker
            label="Filter by Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                sx={{ maxWidth: { xs: "100%", sm: "300px" } }}
              />
            )}
          />
          <Button variant="contained" color="primary" onClick={handleReset}>
            Clear
          </Button>
        </Box>
      </LocalizationProvider>

      {/* News List */}
      <Grid container spacing={3}>
        {loading ? (
          <Typography variant="h6">Loading...</Typography>
        ) : (
          news.length > 0 &&
          news.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: 2,
                  backgroundColor: "#e6e6e694",
                  boxShadow: "none",
                }}
              >
                {/* Date */}
                <Grid item xs={12} md={1}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "70px",
                      marginRight: 2,
                    }}
                  >
                    <Typography variant="h6" color="primary">
                      {formatDate(item.publish_date)}
                    </Typography>
                  </Box>
                </Grid>

                {/* Thumbnail */}
                <Grid item xs={12} md={1}>
                  <CardMedia
                    component="img"
                    sx={{ width: 100, height: 80, borderRadius: 1 }}
                    image={item.company_news_image}
                    alt={item.company_news_title}
                  />
                </Grid>

                {/* News Details */}
                <Grid item xs={12} md={10}>
                  <CardContent sx={{ flex: 1, paddingLeft: 2 }}>
                    <Grid>
                      <Typography variant="h6" noWrap>
                        {item.company_news_title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Typography variant="body2" color="text.secondary">
                        {item.company_news_description}
                      </Typography>
                    </Grid>

                    {/* Time */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        mt: 1,
                        gap: 5,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <AccessTimeIcon fontSize="small" color="action" />
                        <Typography variant="body2" color="text.secondary">
                          {formatTime(item.publish_date)}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default NewsListing;
