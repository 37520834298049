import React, { useState } from "react";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  Alert,
  Container,
  Grid,
} from "@mui/material";

const BulkUploadStudentdata = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("info"); // 'success' or 'error'

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessageType("error");
      setMessage("Please select a file");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = getToken();
      if (!token) {
        console.log("Access denied!");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadstudentfile/savestudentfile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessageType("success");
      setMessage("File uploaded and processed successfully!");
      console.log(response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessageType("error");
      setMessage("Failed to upload the file.");
    }
  };

  const handleDownload = () => {
    const downloadUrl = `/NanoskoolStudents.xlsx`;  // Replace with your actual download URL
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = 'NanoskoolStudents.xlsx';  // Name of the file to be downloaded
    a.click();
  };

  return (
    <Container
      maxWidth="100%" 
      sx={{
        padding: "20px",
      }}
    >
      {/* Displaying any messages */}
      {message && (
        <Alert severity={messageType} sx={{ marginBottom: "20px" }}>
          {message}
        </Alert>
      )}

      <Grid
        container
        spacing={2}
        justifyContent="center" // Centers the grid items
        alignItems="stretch"
      >
        {/* STEP 1 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: "8px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%", // Makes the cards stretch equally in height
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                STEP 1
              </Typography>
              <Typography variant="body1" gutterBottom>
                Click{" "}
                <Button
                  variant="text"
                  color="primary"
                  sx={{ backgroundColor: "#000", color: "#fff !important" }}
                  onClick={handleDownload}  // Trigger the download on click
                >
                  Here
                </Button>{" "}
                to Download Formatted Excel File
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* STEP 2 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: "8px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                STEP 2
              </Typography>
              <Typography variant="body1" gutterBottom>
                Browse Excel File
              </Typography>
              <TextField
                type="file"
                onChange={handleFileChange}
                inputProps={{
                  accept: ".xlsx, .xls",
                }}
                fullWidth
                sx={{ marginTop: "10px" }}
              />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginTop: "10px" }}
              >
                Please browse & upload the latest downloaded formatted Excel
                file with data.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* STEP 3 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: "8px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                STEP 3
              </Typography>
              <Button
                variant="contained"
                color="error"
                onClick={handleUpload}
                sx={{
                  backgroundColor: "#f63238",
                  color: "#fff !important",
                  padding: "6px 20px",
                  textTransform: "uppercase",
                  borderRadius: "7px",
                }}
              >
                Start Upload
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BulkUploadStudentdata;
