import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Pagination,
  Autocomplete,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Eye icon
import NotFound from "../../../assets/NOT_FOUND.png"; // Add the "No Projects Found" image
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { getToken } from "../../../utils/AuthUtils";

const ProjectPage = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [grades, setGrades] = useState([]);
  const [courses, setCourses] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [units, setUnits] = useState([]);
  const navigate = useNavigate();
  const [gradeAssign, setGradeAssign] = useState({
    grade: "",
    gradeDivision: "",
    name: "",
  });

  useEffect(() => {
    console.log("grades: ", gradeAssign);
  }, [gradeAssign]);

  // Fetch Projects based on search term
  const fetchUnit = async (searchTerm = "") => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/project/listproject/byteacher`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
          params: {
            search: searchTerm, // Pass the search term as a query parameter
          },
        }
      );
      setProjects(response.data.data);
    } catch (err) {
      setError(err.message || "Failed to fetch project data");
    }
  };

  // Fetch projects on initial load and when search term changes
  useEffect(() => {
    fetchUnit(searchTerm);
    fetchGrades();
  }, [searchTerm]); // Re-run fetchUnit whenever the search term changes

  useEffect(() => {
    if (gradeAssign.gradeDivision == "") {
      fetchUnit();
      return;
    }

    // tabledata
    const fetchProjectbygrade = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/project/listproject/byteacher/${gradeAssign.gradeDivision}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
            params: {
              search: searchTerm, // Pass the search term as a query parameter
            },
          }
        );
        setProjects(response.data.data);
      } catch (err) {
        setError(err.message || "Failed to fetch project data");
      }
    };

    // dropdeown data
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/project/listproject/byteacher/courses/${gradeAssign.gradeDivision}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
            params: {
              search: searchTerm, // Pass the search term as a query parameter
            },
          }
        );
        setCourses(response.data.data);
      } catch (err) {
        setError(err.message || "Failed to fetch project data");
      }
    };

    fetchProjectbygrade();
    fetchCourses();
  }, [gradeAssign]);

  useEffect(() => {
    if (!selectedCourse) {
      fetchUnit();
      return;
    }

    // tabledata
    const fetchProjectbygradeCourse = async () => {
      try {
        console.log("selected course:  ", selectedCourse);
        
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/project/listproject/byteacher/${gradeAssign.gradeDivision}/${selectedCourse.course_id._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
            params: {
              search: searchTerm, // Pass the search term as a query parameter
            },
          }
        );
        setProjects(response.data.data);
      } catch (err) {
        setError(err.message || "Failed to fetch project data");
      }
    };

    // dropdeown data
    const fetchChapters = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/project/listproject/byteacher/coursesdata/${gradeAssign.gradeDivision}/${selectedCourse.course_id._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
            params: {
              search: searchTerm, // Pass the search term as a query parameter
            },
          }
        );
        setChapters(response.data.data);
      } catch (err) {
        setError(err.message || "Failed to fetch project data");
      }
    };

    fetchProjectbygradeCourse();
    fetchChapters();
  }, [selectedCourse]);

  useEffect(() => {
    if (!selectedChapter) {
      fetchUnit();
      return;
    }

    // tabledata
    const fetchProjectbygradeCourseChapter = async () => {
      try {
        console.log("selected chapter:  ", selectedChapter);
        
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/project/listproject/byteacher/${gradeAssign.gradeDivision}/${selectedCourse.course_id._id}/${selectedChapter._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
            params: {
              search: searchTerm, // Pass the search term as a query parameter
            },
          }
        );
        setProjects(response.data.data);
      } catch (err) {
        setError(err.message || "Failed to fetch project data");
      }
    };

    // dropdeown data
    const fetchUnitsByChapter = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/project/listproject/byteacher/unitdata/${gradeAssign.gradeDivision}/${selectedCourse.course_id._id}/${selectedChapter._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
            params: {
              search: searchTerm, // Pass the search term as a query parameter
            },
          }
        );
        setUnits(response.data.data);
      } catch (err) {
        setError(err.message || "Failed to fetch project data");
      }
    };

    fetchProjectbygradeCourseChapter();
    fetchUnitsByChapter();
  }, [selectedChapter]);

  useEffect(() => {
    if (!selectedUnit) {
      fetchUnit();
      return;
    }

    // tabledata
    const fetchProjectbygradeCourseChapterUnit = async () => {
      try {
        console.log("selected unit:  ", selectedUnit);
        
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/project/listproject/byteacher/${gradeAssign.gradeDivision}/${selectedCourse.course_id._id}/${selectedChapter._id}/${selectedUnit._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
            params: {
              search: searchTerm, // Pass the search term as a query parameter
            },
          }
        );
        setProjects(response.data.data);
      } catch (err) {
        setError(err.message || "Failed to fetch project data");
      }
    };

    fetchProjectbygradeCourseChapterUnit();
  }, [selectedUnit]);

  const fetchGrades = async () => {
    // if (!selectedDeptCategory) return;
    try {
      const token = getToken();
      if (!token) {
        console.log("unauthorized user");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/grade_assign/teacherData`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setGrades(data.data || []);
        console.log("grades: ", data.data);
        // setNewDepartment.department_id = "";
      }
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleEdit = async (id) => {
    navigate(`/student/editproject/${id}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Project List</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {/* filters */}
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          padding={2}
          gap={1}
          sx={{ paddingRight: "0", paddingLeft: "0" }}
        >
          <Grid item xs={12} md={3}>
            <Autocomplete
            size="small"
            sx={{ width: 350 }}
              options={grades.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => `${t.grade_id?.gradename || ""} ${
                      t.grade_division_id || ""
                    }`.trim() === `${value.grade_id?.gradename || ""} ${
                      value.grade_division_id || ""
                    }`.trim())
              )}
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : `${option.grade_id?.gradename || ""} ${
                      option.grade_division_id || ""
                    }`.trim()
              }
              value={
                gradeAssign.grade
                  ? grades.find((item) => item._id === gradeAssign.grade) ||
                    null
                  : ""
              }
              onChange={(event, newValue) => {
                setProjects([]);
                if (newValue === "") {
                  // Reset the grade and gradeDivision when "Select All" is chosen
                  setGradeAssign({
                    grade: "",
                    gradeDivision: "",
                  });
                  setSelectedCourse("");
                  setSelectedChapter("");
                  setSelectedUnit("");
                } else if (newValue && newValue.grade_id) {
                  const gradeDivision = `${newValue.grade_id.gradename || ""} ${
                    newValue.grade_division_id || ""
                  }`.trim();
                  setGradeAssign({
                    grade: newValue._id,
                    gradeDivision,
                  });
                  setSelectedCourse("");
                  setSelectedChapter("");
                  setSelectedUnit("");
                } else {
                  setGradeAssign({
                    grade: "",
                    gradeDivision: "",
                  });
                  setSelectedCourse("");
                  setSelectedChapter("");
                  setSelectedUnit("");
                }
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Grade"
                    size="small"
                    variant="outlined"
                    aria-required="true"
                  />
                </>
              )}
              isOptionEqualToValue={(option, value) =>
                (typeof option === "string" && option === value) ||
                (option._id && value._id && option._id === value._id)
              }
              clearOnEscape
              disablePortal
            />
          </Grid>
          <Grid item xs={12} md={3}>
            {gradeAssign.gradeDivision && (
              <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                  options={courses.filter(
                    (value, index, self) =>
                      index ===
                      self.findIndex((t) => t?.course_id.courses_name === value?.course_id.courses_name)
                  )}
                  getOptionLabel={(option) =>
                    option.course_id.courses_name
                      ? option.course_id.courses_name
                      : "N/A"
                  }
                  value={
                    courses.find((item) => item._id === selectedCourse?._id) ||
                    null
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      const selected = courses.find(
                        (item) => item._id === newValue._id
                      );
  
                      setSelectedCourse(selected);
                      setSelectedChapter("");
                      setSelectedUnit("");
                    } else {
                      setSelectedCourse([]);
                      setSelectedChapter("");
                      setSelectedUnit("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Course" />
                  )}
                  sx={{ width: 350 }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {selectedCourse && (
              <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                  options={chapters.filter(
                    (value, index, self) =>
                      index ===
                      self.findIndex((t) => t?.chapter_name === value?.chapter_name)
                  )}
                  getOptionLabel={(option) =>
                    option?.chapter_name
                      ? option?.chapter_name
                      : "N/A"
                  }
                  value={
                    chapters.find((item) => item?._id === selectedChapter?._id) ||
                    null
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      const selected = chapters.find(
                        (item) => item._id === newValue?._id
                      );
  
                      setSelectedChapter(selected);
                      setSelectedUnit("");
                    } else {
                      setSelectedChapter([]);
                      setSelectedUnit("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Chapter" />
                  )}
                  sx={{ width: 350 }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {selectedChapter && (
              <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                  options={units.filter(
                    (value, index, self) =>
                      index ===
                      self.findIndex((t) => t?.unit_name === value?.unit_name)
                  )}
                  getOptionLabel={(option) =>
                    option?.unit_name
                      ? option?.unit_name
                      : "N/A"
                  }
                  value={
                    units.find((item) => item?._id === selectedUnit?._id) ||
                    null
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      const selected = units.find(
                        (item) => item._id === newValue?._id
                      );
  
                      setSelectedUnit(selected);
                    } else {
                      setSelectedUnit([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Unit" />
                  )}
                  sx={{ width: 350 }}
                />
              </Grid>
            )}
          </Grid>
          <Box flexGrow={1} /> {/* This will push the button to the right */}
        </Box>
      </Grid>

      {/* Check if there are no projects */}
      {projects.length > 0 ? (
        // List of Project Cards
        <Grid container spacing={2}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "15px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  {/* Image Section */}
                  <Box
                    component="img"
                    src={project.project_image} // Replace with actual image source if needed
                    alt={project.project_title}
                    sx={{
                      width: "100%",
                      height: "200px",
                      borderRadius: "12px",
                      mb: 2,
                    }}
                  />

                  {/* Product Name and Info */}
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {project.project_title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 1 }}
                  >
                    Uploaded by: {project.studentData.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 1 }}
                  >
                    {project.student_id.grade_division}
                  </Typography>
                </CardContent>

                {/* View Project Button (Eye Icon) */}
                <IconButton
                  sx={{
                    position: "absolute",
                    bottom: 10,
                    right: 10,
                    color: "#1976d2",
                  }}
                  onClick={() => navigate(`/projectinterface/${project._id}`)} // Navigate to the ProjectView page
                >
                  <VisibilityIcon /> {/* Eye icon */}
                </IconButton>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        // Display message and image when there are no projects
        <Box mt={10} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "50px",
              opacity: 0.3,
            }}
          >
            No projects found
          </Typography>
          <Box
            sx={{ opacity: "0.1", display: "flex", justifyContent: "center" }}
          >
            <img src={NotFound} alt="No Projects Found" />
          </Box>
        </Box>
      )}

      {/* Pagination at the Bottom Left */}
      {/* Uncomment if pagination is needed */}
      {/* <Box mt={4} display="flex" justifyContent="flex-start">
        <Pagination
          count={10}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box> */}
    </Box>
  );
};

export default ProjectPage;
