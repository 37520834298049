import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";

const AssignedCoursesList = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deptNames, setDeptNames] = useState([]);
  const [selectedDeptName, setSelectedDeptName] = useState("");
  const [teacherCategory, setTeacherCategory] = useState([]);
  const [selectedTeacherCategory, setSelectedTeacherCategory] = useState("");
  const [teacherSubCategory, setTeacherSubCategory] = useState([]);
  const [selectedTeacherSubCategory, setSelectedTeacherSubCategory] =
    useState("");
  const [gradeDivision, setGradeDivision] = useState([]);
  const [selectedGradeDivision, setSelectedGradeDivision] = useState("");
  const [teacherCategories, setTeachCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [grades, setGrades] = useState([]);

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);

  useEffect(() => {
    fetchTeachers();
  }, [page, rowsPerPage, searchTerm, startDate, endDate]);

  const fetchTeachers = async () => {
    try {
      const token = getToken();
      if (!token) {
        setError("Access denied!");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_course/teachers?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data?.data) {
        const teachersWithSerial = data.data.map((teacher, index) => ({
          ...teacher,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !teacher.status, // Add this line
        }));
        setTeachers(teachersWithSerial);
        setSerialNumbers(teachersWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      console.error("Error fetching teachers:", err);
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };

  // department category select list
  useEffect(() => {
    fetchDeptNames();
  }, []);

  const fetchDeptNames = async () => {
    // if (!selectedDeptCategory) return;
    try {
      const token = getToken();
      if (!token) {
        console.log("unauthorized user");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/department/getassigneddepartmentstosc`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setDeptNames(data.data || []);
        console.log("teachers related department: ", data.data);
        // setNewDepartment.department_id = "";
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  // department category select list
  useEffect(() => {
    const fetchTeachCategories = async () => {
      // if (!selectedDeptCategory) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/teachercategory`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setTeachCategories(data.data || []);
          console.log("teachers categories: ", data.data);
          // setNewDepartment.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTeachCategories();
  }, []);

  // get teacher category list by dept name
  useEffect(() => {
    console.log("selected department category: ", selectedDeptName);

    const fetchTeacherCategory = async () => {
      if (!selectedDeptName) {
        fetchDeptNames();
        return;
      }
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }

        const response1 = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/teachers/dept/${
            selectedDeptName.department_id
          }?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data1 = await response1.json();
        if (data1?.data) {
          const teachersWithSerial = data1.data.map((teacher, index) => ({
            ...teacher,
            serial: page * rowsPerPage + index + 1,
            statusToggle: !teacher.status, // Add this line
          }));
          setTeachers(teachersWithSerial);
          setTeacherCategory(teachersWithSerial);
          setSerialNumbers(teachersWithSerial.map((t) => t.serial));
          setTotalItems(data1.pagination.totalItems); // Set totalItems for pagination
        } else {
          setError("No teachers found");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTeacherCategory();
  }, [selectedDeptName]);

  // get teacher subcategory list by teacher category
  useEffect(() => {
    console.log("selected teacher category: ", selectedTeacherCategory);

    const fetchTeacherSubCategory = async () => {
      if (!selectedTeacherCategory) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/teacher/sub/teach/${selectedTeacherCategory._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setTeacherSubCategories(data.data || []);
          console.log("teachers related department: ", data.data);
          // setNewDepartment.department_id = "";
        }

        const response1 = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/teachers/cat/${
            selectedTeacherCategory._id
          }?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data1 = await response1.json();
        if (data1?.data) {
          const teachersWithSerial = data1.data.map((teacher, index) => ({
            ...teacher,
            serial: page * rowsPerPage + index + 1,
            statusToggle: !teacher.status, // Add this line
          }));
          setTeachers(teachersWithSerial);
          setTeacherSubCategory(teachersWithSerial);
          setSerialNumbers(teachersWithSerial.map((t) => t.serial));
          setTotalItems(data1.pagination.totalItems); // Set totalItems for pagination
        } else {
          setError("No teachers found");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTeacherSubCategory();
  }, [selectedTeacherCategory]);

  useEffect(() => {
    console.log("selected teacher sub category: ", selectedTeacherSubCategory);

    const fetchGradeDivision = async () => {
      if (!selectedTeacherSubCategory) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        // grades for select box
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/teachers/grades/${selectedTeacherCategory._id}/${selectedTeacherSubCategory._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setGrades(data.data || []);
          console.log("grades for select box: ", data.data);
          // setNewDepartment.department_id = "";
        }

        const response1 = await fetch(
          `${
            process.env.REACT_APP_BACKEND
          }/api/assign_course/teachers/gradediv/${
            selectedTeacherCategory._id
          }/${selectedTeacherSubCategory._id}?page=${
            page + 1
          }&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data1 = await response1.json();
        if (data1?.data) {
          const teachersWithSerial = data1.data.map((teacher, index) => ({
            ...teacher,
            serial: page * rowsPerPage + index + 1,
            statusToggle: !teacher.status, // Add this line
          }));
          setTeachers(teachersWithSerial);
          setGradeDivision(teachersWithSerial);
          setSerialNumbers(teachersWithSerial.map((t) => t.serial));
          setTotalItems(data1.pagination.totalItems); // Set totalItems for pagination
        } else {
          setError("No teachers found");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchGradeDivision();
  }, [selectedTeacherSubCategory]);

  // get table data by selected grade division
  useEffect(() => {
    console.log("selected grade divsion: ", selectedGradeDivision);

    const fetchData = async () => {
      if (!selectedGradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        console.log("Selected grade division: ", selectedGradeDivision);

        // const response = await fetch(
        //   `${process.env.REACT_APP_BACKEND}/api/assign_department/getdepartmentsubcategoryBycategoryid/${selectedDeptName._id}`,
        //   {
        //     method: "GET",
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );
        // const data = await response.json();
        // if (response.ok) {
        //   setTeacherCategory(data.data || []);
        //   console.log("teacher category: ", data.data);
        //   // setNewDepartment.department_id = "";
        // }

        const response1 = await fetch(
          `${
            process.env.REACT_APP_BACKEND
          }/api/assign_course/teachers/assigndata/${
            selectedTeacherCategory._id
          }/${selectedTeacherSubCategory._id}/${
            selectedGradeDivision.grade
          }?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data1 = await response1.json();
        if (data1?.data) {
          const teachersWithSerial = data1.data.map((teacher, index) => ({
            ...teacher,
            serial: page * rowsPerPage + index + 1,
            statusToggle: !teacher.status, // Add this line
          }));
          setTeachers(teachersWithSerial);
          setGradeDivision(teachersWithSerial);
          setSerialNumbers(teachersWithSerial.map((t) => t.serial));
          setTotalItems(data1.pagination.totalItems); // Set totalItems for pagination
        } else {
          setError("No teachers found");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchData();
  }, [selectedGradeDivision]);

  // get gradeDivision

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const deleteTeacher = async (id) => {
    // if clicking ok do the rest
    try {
      const token = getToken();
      if (!token) {
        setError("Access denied!");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_course/${deleteId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setSnackbarMessage("Assign course deleted successfully");
        setOpenSnackbar(true);
        fetchTeachers();
        handleCloseDeleteDialog();
      }
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 100));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateTeacherStatus(id, newStatus); // Update parent status in the backend
  };
  const updateTeacherStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_course/${id}/status_assigncourse`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Status updated successfully");
      setOpenSnackbar(true);
      fetchTeachers(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update course assigned status");
    }
  };

  const moveToAssignCourse = () => {
    navigate("/school/assigned-course");
  };

  useEffect(() => {
    console.log("selected teacher categoriiiiii: ", selectedTeacherCategory);
  }, []);
  // const editNavigation = (teacher)=>{
  //   navigate(`school/edit-assigned-course/${teacher._id}`)
  // }
  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#e0e0e0" }}
        p={1}
        mt={2}
      >
        <Typography variant="h4">ASSIGNED COURSE LIST</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }} // Stack fields on small screens and row on larger screens
        alignItems="center"
        paddingY={2}
        gap={1}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              size="small"
              options={deptNames}
              getOptionLabel={(option) =>
                option.department_name ? option.department_name : "N/A"
              }
              value={
                deptNames.find(
                  (item) =>
                    item.department_id === selectedDeptName?.department_id
                ) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selected = deptNames.find(
                    (item) => item.department_id === newValue.department_id
                  );
                  setSelectedDeptName(selected);
                  setSelectedTeacherCategory("");
                  setSelectedTeacherSubCategory(""); // Reset dependent fields
                  setSelectedGradeDivision("");
                } else {
                  setSelectedDeptName([]); // Clear if nothing is selected
                  setSelectedTeacherCategory("");
                  setSelectedTeacherSubCategory(""); // Reset dependent fields
                  setSelectedGradeDivision("");
                  fetchTeachers();
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Department" />
              )}
            />
          </Grid>
          {/* Teacher Category Autocomplete */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              size="small"
              options={teacherCategories.filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => t?.teacher_name === value?.teacher_name)
              )}
              getOptionLabel={(option) =>
                option.teacher_name ? option.teacher_name : "N/A"
              }
              value={
                teacherCategories.find(
                  (item) => item._id === selectedTeacherCategory?._id
                ) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selected = teacherCategories.find(
                    (item) => item?._id === newValue?._id
                  );
                  setSelectedTeacherCategory(selected);
                  setSelectedTeacherSubCategory(""); // Reset dependent fields
                  setSelectedGradeDivision("");
                } else {
                  setSelectedTeacherCategory([]); // Clear if nothing is selected
                  setSelectedTeacherSubCategory(""); // Reset dependent fields
                  setSelectedGradeDivision("");
                  fetchTeachers();
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Teacher Category" />
              )}
            />
          </Grid>
          {/* Teacher Sub Category Autocomplete */}
          {selectedTeacherCategory && (
            <Grid item xs={12} md={3}>
              <Autocomplete
                fullWidth
                size="small"
                options={teacherSubCategories.filter(
                  (value, index, self) =>
                    index ===
                    self.findIndex((t) => t.teacher_name === value.teacher_name)
                )}
                disabled={!selectedTeacherCategory}
                getOptionLabel={(option) =>
                  option.teacher_name ? option.teacher_name : "N/A"
                }
                value={
                  teacherSubCategories.find(
                    (item) => item._id === selectedTeacherSubCategory._id
                  ) || null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    const selected = teacherSubCategories.find(
                      (item) => item._id === newValue._id
                    );
                    setSelectedTeacherSubCategory(selected);
                    setSelectedGradeDivision(""); // Reset dependent field
                  } else {
                    setSelectedTeacherSubCategory([]); // Clear if nothing is selected
                    setSelectedGradeDivision(""); // Reset dependent field
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Teacher Subcategory" />
                )}
              />
            </Grid>
          )}
          {/* Grade Division Autocomplete */}
          {selectedTeacherSubCategory && (
            <Grid item xs={12} md={3}>
              <Autocomplete
                size="small"
                options={grades} // No filtering applied
                disabled={
                  !selectedTeacherSubCategory || !selectedTeacherCategory
                }
                getOptionLabel={(option) => (option.grade ? option.grade : "")}
                value={
                  grades.find((item) => item.id === selectedGradeDivision.id) ||
                  null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    const selected = grades.find(
                      (item) => item.id === newValue.id
                    );
                    setSelectedGradeDivision(selected ? selected : "");
                  } else {
                    setSelectedGradeDivision(""); // Reset value when no option is selected
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Class" />
                )}
              />
            </Grid>
          )}
          {/* Spacer for Button alignment */}
          <Box flexGrow={1} />

          {/* Assign Button */}
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent="flex-end"
            sx={{ order: 2, marginLeft: "auto" }}
          >
            <Button
              onClick={moveToAssignCourse}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{
                backgroundColor: "#e0e0e0",
              }}
            >
              Assign
            </Button>
          </Grid>
        </Grid>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Teacher Name</TableCell>
              <TableCell>Department Name</TableCell>
              <TableCell>Grade</TableCell>
              <TableCell>Division</TableCell>
              <TableCell>Assigned Course</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.length > 0 ? (
              teachers.map((teacher) => (
                <TableRow key={teacher._id}>
                  <TableCell>{teacher.serial}</TableCell>
                  <TableCell>
                    {teacher.teachercategoryname.teacher_name}
                  </TableCell>
                  <TableCell>
                    {teacher.teachersubcategoryname.teacher_name}
                  </TableCell>
                  <TableCell>{teacher.teachername.name}</TableCell>
                  <TableCell>
                    {teacher.departmentname.department_name}
                  </TableCell>
                  <TableCell>{teacher.gradename.gradename}</TableCell>
                  <TableCell>{teacher.grade_division_id}</TableCell>
                  {/* <TableCell>{teacher.course_names.join(", ")}</TableCell> */}
                  <TableCell>{teacher.coursesname.courses_name}</TableCell>
                  <TableCell>
                    <Switch
                      checked={teacher.status}
                      onChange={() =>
                        handleStatusToggle(teacher._id, teacher.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell>
                    {/* <IconButton
                      color="primary"
                      onClick={() => navigate(`${teacher._id}`)}
                      // onClick={() =>editNavigation(teacher)}
                    >
                      <EditIcon />
                    </IconButton> */}
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDeleteDialog(teacher._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  {/* ... other cells */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No courses available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 100 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this assign course?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteTeacher}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssignedCoursesList;
