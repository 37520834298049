import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Alert,
  Grid,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";
 
const divisions = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"]; // Available divisions
 
const ClassList = () => {
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState({});
  const [grades, setGrades] = useState([]);
 
  useEffect(() => {
    const fetchGrades = async () => {
      setLoading(true);
      const token = getToken();
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/grade/assignedgrade`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setGrades(data.data); // Set grades from the response
          console.log("all grades: ", data.data);
        } else {
          setError("Failed to fetch grades");
        }
      } catch (error) {
        setError("Error fetching grades");
      } finally {
        setLoading(false);
      }
    };
 
    fetchGrades();
  }, []);
 
  useEffect(() => {
    const fetchUserClasses = async () => {
      setLoading(true);
      const token = getToken();
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/class`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          const userClasses = data.data.reduce(
            (acc, { grade_id: { _id }, division }) => {
              if (!acc[_id]) acc[_id] = []; // Use grade ID as key
              acc[_id].push(division); // Push division into the array for that grade ID
              return acc;
            },
            {}
          );
          setSelectedClasses(userClasses);
          console.log("set selected classes: ", userClasses);
        } else {
          setError("Failed to fetch user classes");
        }
      } catch (error) {
        setError("Error fetching user classes");
      } finally {
        setLoading(false);
      }
    };
 
    fetchUserClasses();
  }, []);
 
  const handleCheckboxChange = (gradeId, division) => (event) => {
    const { checked } = event.target;
    setSelectedClasses((prev) => {
      const selectedDivisions = prev[gradeId] || [];
      const updatedDivisions = checked
        ? [...new Set([...selectedDivisions, division])]
        : selectedDivisions.filter((div) => div !== division);
      return {
        ...prev,
        [gradeId]: updatedDivisions,
      };
    });
  };
 
  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const token = getToken();
    try {
      const body = Object.entries(selectedClasses).map(
        ([gradeId, divisions]) => ({
          gradeId,
          divisions,
        })
      );
 
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/class`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        setSnackbarMessage("Changes saved successfully");
        setOpenSnackbar(true);
      } else {
        setError("Failed to save changes");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setError("Failed to save changes");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
 
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };
 
  return (
    <Container maxWidth="100%">
      <Box
        mt={2}
        mb={2}
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0">
        <Typography variant="h4">
        Set Divisions
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #ccc", borderRadius: 2, padding: 3 }}>
        <FormGroup>
          <Grid container spacing={2}>
            {grades.map((grade) => {
              const className = grade.grade_name; // Use gradename from MongoDB
              return (
                <Grid item xs={12} key={grade.grade_id}>
                  <Typography variant="h6" gutterBottom>
                    {className}
                  </Typography>
                  <Grid container spacing={1}>
                    {divisions.map((division) => (
                      <Grid item xs={2} key={division}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={`${className}-${division}`}
                              checked={
                                selectedClasses[grade.grade_id]?.includes(
                                  division
                                ) || false
                              }
                              onChange={handleCheckboxChange(
                                grade.grade_id,
                                division
                              )}
                            />
                          }
                          label={division}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading}
        sx={{ mt: 2, backgroundColor: "#e0e0e0" }}>
        {loading ? "Saving..." : "Save Changes"}
      </Button>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};
 
export default ClassList;